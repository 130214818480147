import logo from "./logo.svg";
import React from "react";
import "./App.css";
import OpeningScreen from "./components/OpeningScreen/OpeningScreen";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <OpeningScreen></OpeningScreen>
      </div>
    );
  }
}

export default App;
