import "./introMessage.css";
const IntroMessage = () => {
  return (
    <div className="IntroMessage">
      <h1>GABOR STRENGER</h1>
      <h2>Real Estate & Automotive photograpy</h2>
      <h3>Budapest</h3>
      <div className="LinkSet">
        <a href="https://www.instagram.com/_gaborstrenger_?igsh=dm9mcTVnOHp1MDZh">instagram</a>
        <span> | </span>
        <a href="https://www.flickr.com/photos/185890831@N08/">flickr</a>
        <span> | </span>
        <a href="https://www.facebook.com/strengergabor">facebook</a>
      </div>
    </div>
  );
};

export default IntroMessage;
