import "./OpeningScreen.css";
import IntroMessage from "./IntroMessage/IntroMessage";
import Slideshow from "./slideshow/Slideshow";
const OpeningScreen = () => {
  return (
    <div className="OpeningScreen">   
        <div className="hc2">
          <Slideshow
            msinterval={4000}
            links={[
              "https://live.staticflickr.com/65535/52656782739_b01c2f7349_k_d.jpg",
              "https://live.staticflickr.com/65535/52656944690_7cc930f5d0_k_d.jpg",
              "https://live.staticflickr.com/65535/52656986250_6b69258763_k_d.jpg",
              "https://live.staticflickr.com/65535/52656952270_8756b40436_k_d.jpg",
              "https://live.staticflickr.com/65535/52656782509_a87d95447b_k_d.jpg",
              "https://live.staticflickr.com/65535/52656782224_bf79c07508_k_d.jpg",
              "https://live.staticflickr.com/65535/50594699642_2a8dbc7133_o_d.jpg"
            ]}
          ></Slideshow>
          <IntroMessage></IntroMessage>
        </div>
    </div>
  );
};
export default OpeningScreen;
