import "./Slideshow.css";
import React from "react";

class Slideshow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentLink: "", imageIndex: 0 };
  }
  componentDidMount = () => {
    this.setState({ currentLink: this.props.links[0] });
    const updateInterval = this.props.msinterval;
    var timerID = setInterval(this.tick, updateInterval);
  };
  componentWillMount = () => {
    clearInterval(this.timerID);
  };
  componentWillUnmount = () => {
    clearInterval(this.timerID);
  };
  nextElementChangeHandler(array) {
    let nextindex = this.state.imageIndex + 1;
    const copyarray = [...array];
    if (copyarray.length - 1 < nextindex) {
      nextindex = 0;
    }
    this.setState({ imageIndex: nextindex });
    return array[nextindex];
  }
  tick = () => {
    var newLink = this.nextElementChangeHandler(this.props.links);
    this.setState({ currentLink: newLink });
  };

  render() {
    return (
      <div className="Slideshow" onClick={this.tick}>
        <div className="fadeLayer" onClick={this.tick}></div>
        <img
          className="ImageTag"
          src={this.state.currentLink}
          alt="background1"
        ></img>
      </div>
    );
  }
}
export default Slideshow;
